import React from "react"
import { graphql } from "gatsby";
import styled from "styled-components";
import get from 'lodash/get'
import Layout from "../components/layout";
import SEO from "../components/seo";
import BgImage from "../components/BgImage";
import Reviews from "../components/section/Reviews";
import IntroContainer from "../components/introContainer";

class GalleryPage extends React.Component {
  render(data) {

  const page = get(this.props, 'data.allNodeGallery.edges[0].node');
  const bannerImage = get(this.props, 'data.allNodeGallery.edges[0].node.relationships.field_banner_image.localFile.childImageSharp.fluid');

  const galleryImages = get(this.props, 'data.allNodeGallery.edges[0].node.relationships.field_images');

return (

    <Layout>
      <SEO title={page.title} />

      <BgImage title={page.title} fluid={bannerImage} overlayColor="#00000069" height="400px">
      	<Container>
      		<h1>{page.title}</h1>
      	</Container>
      </BgImage>


      
      <IntroContainer> 
        <div dangerouslySetInnerHTML={{ __html: page.body.processed }} />
      </IntroContainer>

      <GalleryContainer>
         <div className="image-grid">
         
            {
              galleryImages.map(image => (
                <div className="image-item">
                  <img src={image.localFile.childImageSharp.fluid.src} key={image.localFile.childImageSharp.id} alt="brickwork" />
                </div>
              ))
            }
         
          </div>
      </GalleryContainer>

       <Reviews />

    </Layout>
    );
  }
}

export const GalleryPageQuery = graphql`
  query {
    allNodeGallery(filter: {drupal_internal__nid: {eq: 4}}) {
      edges {
        node {
          title
          body {
            processed
          }
          relationships {
            field_banner_image {
              localFile {
                childImageSharp {
                  fluid(maxWidth: 1000) {
                    ...GatsbyImageSharpFluid
                  }
                }
              }
            }

            field_images {
              localFile {
                childImageSharp {
                  id
                  fluid {
                    src
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;


const Container = styled.div`
	height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    max-width: 780px;
    margin: 50px auto;
    text-align: center;
    flex-direction: column;

    h1,
    h2 {
      color: #fff;
    }

    h2 {
    	font-size: 20px;
    }
`

const GalleryContainer = styled.div`
  max-width: 1200px;
  margin: 0 auto 60px auto;

   @media(max-width: 768px) {
    margin: 60px 30px;
  }

  .image-grid {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    grid-auto-rows: minmax(50px, auto);
  }

  .image-grid .image-item:nth-child(5n) {
    grid-column-end: span 2;
  }

  .image-grid img {
    display: flex;
    width: 100%;
    height: 100%;
    object-fit: cover;
    margin-bottom: 0;
  }
`


export default GalleryPage
